import React from 'react'
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { CookiesProvider } from 'react-cookie'
import RoutesComponent from './_Components/Routes'

function App() {
  return (
    <CookiesProvider>
      <Router>
        <Routes>
          {RoutesComponent.map((route, i) => (
            Array.isArray(route.path) ?
              route.path.map((routeSub, j) => (
                <Route
                  key={j}
                  exact path={routeSub}
                  element={<route.component routes={route.routes} />}
                />
              ))
            :
              <Route
                key={i}
                exact path={route.path}
                element={<route.component routes={route.routes} />}
              />
          ))}
        </Routes>
      </Router>
    </CookiesProvider>
  )
}

export default App