import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './js/App'

import './css/style.scss'

const rootElement = document.getElementById('root') // eslint-disable-line no-undef
const root = ReactDOM.createRoot(rootElement)

root.render(
  <App />
)

if(module.hot) // eslint-disable-line no-undef  
  module.hot.accept() // eslint-disable-line no-undef  

